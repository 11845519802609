<template>
  <div class="text-left bg-white-alt">
    <b-alert :show="alert.dismissCountDown" fade id="alert"
              @dismiss-count-down="countDownChanged" class="alert-success">{{alert.notification}}
    </b-alert>
    <b-alert :show="alertDanger.dismissCountDown" fade id="alert-danger"
              @dismiss-count-down="countDownChangedDanger" class="alert-danger multi-line pre-formatted">
            {{ alertDanger.notification }}
        <div class="x dismiss-x" style="width: 25px; height: 25px; position: absolute; background-color: transparent; right: 10px; top: 11px" @click="() => {this.alertDanger.dismissCountDown = 0}"></div>
    </b-alert>
    <NavBarNew :greenLogo='true'/>
    <main>
      <section class="tr-container tr-glasses tr-glasses-kontakt--green">
        <h1 class="tr__h1" ref="contact-us">{{ $t("public.contactPage.contactUs") }}</h1>
        <form action="" @submit.prevent="onSubmit" novalidate>
                <div class="form-card d-flex">
                    <div class="tr-left-part tr-border-right tr-right-dashed-line w-100 d-flex flex-column">
                        <h3>{{ $t("public.contactPage.writeUs") }}</h3>
                        <div class="d-flex h-100 flex-column mx-auto" style="gap: 50px">
                            <div class="d-flex flex-wrap" style="gap: 40px">
                                <TrekioInput darkBg width="260px" dynamicLabel id="name" trim v-model="form.name" :label="$t('inputLabels.name')"
                                  :error="error.name" maxlength="100" required
                                />
                                <TrekioInput darkBg width="260px" dynamicLabel id="email" trim v-model="form.email" :label="$t('inputLabels.login')"
                                  :error="error.email" maxlength="100" required
                                />
                              </div>
                              <TrekioTextarea darkBg style="height: 100%" id="msgBody" :placeholder="$t('public.contactPage.inputPlaceholderContactMessage') + ' *'" trim v-model="form.msgBody" min="0" maxlength="1024"
                                :error="error.msgBody" required
                              />
                        </div>
                    </div>
                    
                    <div class="tr-right-part tr-border-left d-flex flex-column jusify-content-center">
                      <TrekioButton primary :isLoading="loading != ''" :loadingText="loading" width="260px">{{ $t("buttons.send") }}</TrekioButton>
                    </div>
                </div>
            </form>
      </section>
    </main>
    <Footer style="margin-top: 173px"/>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import ContactEmailService from "@/services/ContactEmailService"

export default {

  name: "ContactPage",
  components: {
    Footer,
  },

  data() {
    return {
      form: {
        name: '',
        email: '',
        msgBody: ''
      },
      loading: '',
      error: {
        name: '',
        email: '',
        msgBody: ''
      },
      alert: {
        dismissSecs: 6,
        dismissCountDown: 0,
        showDismissibleAlert: false,
        notification: ''
      },
      alertDanger: {
        dismissSecs: 999,
        dismissCountDown: 0,
        showDismissibleAlert: false,
        notification: ''
      },
    }
  },

  methods: {
    async recaptcha() {
      const token = await this.$recaptchaToken('CONTACT_MAIL')
      return token
    },
    validateInputs() {
          let errorCount = 0
          const inputs = document.querySelectorAll("input, textarea")
          inputs.forEach(input => {this.error[input.id] = ''})
          inputs.forEach(input => {
              if (input.required && !input.value) {
                  this.error[input.id] = this.$t("inputErrors.required")
                  errorCount += 1
              }
          })

          if (this.form.email) {
              const emailRegex = /^(?=.{1,64}@)[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)*@+[^-][A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$/
              if (!this.form.email.match(emailRegex)) {
                  errorCount += 1
                  this.error.email = this.$t("inputErrors.incorrectEmailFormat")
              }
          }
          errorCount > 0 && document.querySelector("h1").scrollIntoView({behavior: 'smooth', block: 'nearest'})
          return errorCount > 0 ? false : true
    },

    async onSubmit() {
      this.alert.dismissCountDown = this.alertDanger.dismissCountDown = 0
      if (!this.validateInputs()) return
        this.$refs["contact-us"].scrollIntoView({ behavior: "smooth" })
        this.loading = this.$t("loading.send")

        const retoken = await this.recaptcha()

        ContactEmailService.sendContactEmail(this.form, retoken)
          .then(() => {
            this.form.name = this.form.email = this.form.msgBody = ''
            this.showAlert(this.$t("alerts.contactFormSubmit"))
            this.loading = ''
          })
          .catch((err) => {
            this.showAlertDanger(this.$t('alerts.errorSubmittingForm'))
            console.log(err)
            this.loading = ''
          })
    },

    showAlert(notification) {
        this.alert.dismissCountDown = this.alert.dismissSecs;
        this.alert.notification = notification;
    },

    countDownChanged(dismissCountDown) {
      this.alert.dismissCountDown = dismissCountDown
    },

    showAlertDanger: function(notification) {
        this.alertDanger.dismissCountDown = this.alertDanger.dismissSecs;
        this.alertDanger.notification = notification;
    },

    countDownChangedDanger (dismissCountDown) {
      this.alertDanger.dismissCountDown = dismissCountDown
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../global";

h3 {
  line-height: 25px;
  font-size: 25px;
  max-width: 560px;
  width: 100%;
  margin: 0 auto 30px;
}

@media screen and (min-width: 1101px) {
  .form-card {
      width: 100%;
      max-width: 1160px;
      height: 485px;
  }    
}
</style>